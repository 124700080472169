import React from 'react'
import { flowRight, get } from 'lodash'
import { connect } from 'react-redux'
import { Container, Graphics, Text } from '@inlet/react-pixi'
import pixiLink from '~/helpers/pixiLink'
import * as PIXI from 'pixi.js'

class Link extends React.Component {
  container = React.createRef()

  componentDidMount () {
    this.initLink()
  }

  initLink () {
    const { link, target, easterEgg } = this.props

    const { current: element } = this.container
    const isActive = !easterEgg

    pixiLink({
      element,
      link,
      target,
      isActive,
    })
  }

  render () {
    const { y, label, body } = this.props

    const width = 260
    const height = 50

    const x = (body.width - width) / 2
    const newY = y * get(body, 'scaleFactor', 1)

    const background = (
      <Graphics
        draw={g => {
          g.lineStyle(2, 0xffffff, 1)
          g.beginFill(0x000000, 0.4)
          g.drawRect(0, 0, width, height)
          g.endFill()
        }}
      />
    )

    const textStyle = new PIXI.TextStyle({
      align: 'center',
      fontFamily: '"Futura", sans-serif',
      letterSpacing: 1.5,
      fontSize: 17,
      fontWeight: 300,
      fill: '#ffffff',
    })

    const labelNode = (
      <Text
        text={label}
        anchor={0.5}
        x={width * 0.5}
        y={height * 0.5}
        resolution={0}
        style={textStyle}
      />
    )

    return (
      <Container
        ref={this.container}
        interactive={true}
        x={x}
        y={newY}>
        {background}
        {labelNode}
      </Container>
    )
  }
}

const mapStateToProps = ({ viewport, body, easterEgg }) => ({
  viewport,
  body,
  easterEgg,
})

export default flowRight(connect(mapStateToProps))(Link)
