import React from 'react'
import { flowRight, map, get } from 'lodash'
import { connect } from 'react-redux'
import { Container, Sprite } from '@inlet/react-pixi'
import scrollWithEase from '~/helpers/scrollWithEase'
import * as PIXI from 'pixi.js'
import Blinding from './Blinding'
import Random from './Random'
import Marquee from './Marquee'

class NormalContent extends React.Component {
  blinding = React.createRef()
  random = React.createRef()

  state = {
    scroll: {},
  }

  constructor () {
    super()

    this.setScroll = this.setScroll.bind(this)
  }

  componentDidMount () {
    scrollWithEase.get(this.setScroll)
    scrollWithEase.on('scroll', this.setScroll)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setScroll)
  }

  setScroll (scroll) {
    this.setState({ scroll })
  }

  render () {
    const { scroll } = this.state
    const { body, view1 } = this.props

    const area = (
      <Sprite
        texture={PIXI.Texture.EMPTY}
        width={body.width}
        height={body.height}
      />
    )

    const marqueeNodes = map(get(view1, 'marquees'), data => {
      return <Marquee
        key={data.id}
        data={data} />
    })

    return (
      <Container>
        <Random />
        <Blinding />
        <Container y={-scroll.value}>
          {area}
          {marqueeNodes}
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = ({ body, triggers, view1 }) => ({
  body,
  triggers,
  view1,
})

export default flowRight(connect(mapStateToProps))(NormalContent)
