import React from 'react'
import Button from '~/components/Button'

class Link extends React.Component {
  render () {
    const { label, link, target } = this.props

    return (
      <div className="wrapper">
        <Button
          label={label}
          background={true}
          link={link}
          target={target} />

        <style jsx>{`
          .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            width: 100%;
            letter-spacing: 0.1em;
            padding: 60px;
          }
          @media (min-width: 600px) {
            .wrapper {
              padding: 80px;
            }
          }
        `}</style>
      </div>
    )
  }
}

export default Link
