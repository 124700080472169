import React from 'react'
import scrollWithEase from '~/helpers/scrollWithEase'

function restoreScroll (WrappedComponent) {
  class RestoreScroll extends React.Component {
    componentDidMount () {
      const { config } = this.props

      let scrollTo = 0

      if (config.scroll) {
        scrollTo = config.scroll
      }

      scrollWithEase.reset(scrollTo)
    }
    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  return RestoreScroll
}

export default restoreScroll
