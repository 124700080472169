import React from 'react'
import { flowRight, clone } from 'lodash'
import { connect } from 'react-redux'
import { Sprite } from '@inlet/react-pixi'
import pixiBoundsHoc from '~/hoc/pixiBoundsHoc'
import image from '~/media/other/white.png'

class Block extends React.Component {
  render () {
    const { bounds, scroll } = this.props

    const { delta } = scroll

    const isScrolling = Math.abs(delta) > 4
    const direction = Math.sign(delta)
    const alpha = isScrolling ? 1 : 0

    const position = clone(bounds)

    position.height = Math.min(Math.max(Math.abs(delta) - 4, 0), 200)

    if (direction > 0) {
      position.y = bounds.y + bounds.height
    } else {
      position.y = bounds.y - position.height
    }

    const { x, y, width, height } = position

    return (
      <Sprite
        x={x}
        y={y}
        width={width}
        height={height}
        image={image}
        alpha={alpha}
      />
    )
  }
}

const mapStateToProps = ({ body }) => ({
  body,
})

export default flowRight(
  connect(mapStateToProps),
  pixiBoundsHoc
)(Block)
