import { TweenMax } from 'gsap'

export default function ({ value, duration }, callback = () => {}) {
  TweenMax.to(
    {
      scroll: window.pageYOffset,
    },
    0.5,
    {
      scroll: value,
      onUpdate: tween => window.scrollTo(0, tween.target.scroll),
      onUpdateParams: ['{self}'],
      onComplete: callback,
    }
  )
}
