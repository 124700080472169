import React from 'react'
import { flowRight } from 'lodash'
import { withPixiApp } from '@inlet/react-pixi'

function pixiTicker (WrappedComponent) {
  class PixiTicker extends React.Component {
    onTicker = []

    constructor (props) {
      super(props)

      this.addToTicker = this.addToTicker.bind(this)
    }

    componentWillUnmount () {
      this.emptyTicker()
    }

    addToTicker (fn) {
      const { app } = this.props

      app.ticker.add(fn)

      this.onTicker.push(fn)
    }

    emptyTicker () {
      const { app } = this.props

      while (this.onTicker.length) {
        app.ticker.remove(this.onTicker.shift())
      }
    }

    render () {
      const { app } = this.props

      return (
        <WrappedComponent
          app={app}
          ref={ref => {
            this.cmp = ref
          }}
          addToTicker={this.addToTicker}
          {...this.props}
        />
      )
    }
  }

  return flowRight(withPixiApp || PixiTicker)(PixiTicker)
}

export default pixiTicker
