import React from 'react'
import { flowRight, get } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import Button from './Button'
import view1 from '~/media/icons/view-1.svg'
import view2 from '~/media/icons/view-2.svg'
import view3 from '~/media/icons/view-3.svg'
import scrollWithEase from '~/helpers/scrollWithEase'
import { TweenMax } from 'gsap'

class SwitchView extends React.Component {
  state = {
    isVisible: true,
  }

  wrapper = React.createRef()

  constructor () {
    super()

    this.toggle = this.toggle.bind(this)
  }

  componentDidMount () {
    this.setOnEnter()
    this.setOnLeave()

    scrollWithEase.on('scroll', this.toggle)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.toggle)
  }

  setOnEnter () {
    const { onEnter } = this.props
    const { current: elm } = this.wrapper

    TweenMax.set(elm.children, {
      scale: 0.9,
      opacity: 0,
    })

    onEnter(onComplete => {
      TweenMax.staggerTo(
        elm.children,
        0.35,
        {
          scale: 1,
          opacity: 1,
          ease: 'Sine.easeInOut',
          onComplete,
        },
        0.15
      )
    })
  }

  setOnLeave () {
    const { onLeave } = this.props
    const { current: elm } = this.wrapper

    onLeave(onComplete => {
      TweenMax.staggerTo(
        elm.children,
        0.35,
        {
          scale: 0.9,
          opacity: 0,
          ease: 'Sine.easeInOut',
          onComplete,
        },
        0.15
      )
    })
  }

  toggle (scroll) {
    const { isVisible: oldIsVisible } = this.state
    const { version } = this.props

    let isVisible = true

    const isMobile = version === 'mobile'

    if (isMobile && get(scroll, 'direction') < 0) {
      isVisible = false
    }

    if (isVisible !== oldIsVisible) {
      this.setState({ isVisible })
    }
  }

  changeView (view) {
    const { changeView } = this.props

    changeView({
      key: view,
    })
  }

  isActive (view) {
    const { activeView } = this.props

    return activeView.key === view
  }

  render () {
    const { version, views } = this.props
    const { isVisible } = this.state
    const { VIEW_1, VIEW_2, VIEW_3 } = views

    const isMobile = version === 'mobile'
    const isDesktop = version === 'desktop'

    const translateY = isVisible ? '0%' : '100%'

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        {isDesktop && (
          <Button
            svg={view1}
            isActive={this.isActive(VIEW_1)}
            onClick={() => this.changeView(VIEW_1)}
          />
        )}

        {isMobile && (
          <Button
            svg={view3}
            isActive={this.isActive(VIEW_3)}
            onClick={() => this.changeView(VIEW_3)}
          />
        )}

        <Button
          svg={view2}
          isActive={this.isActive(VIEW_2)}
          onClick={() => this.changeView(VIEW_2)}
        />

        <style jsx>{`
          .wrapper {
            position: fixed;
            bottom: 0;
            left: 50%;
            padding-bottom: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translate(-50%, ${ translateY });
            transition: 0.3s transform 0.15s ease-in-out;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ version, activeView, views }) => ({
  version,
  activeView,
  views,
})

const mapDispatchToProps = dispatch => ({
  changeView: payload => dispatch(Actions.changeView(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SwitchView)
