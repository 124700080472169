import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import MobileDetect from 'mobile-detect'
import { Actions } from '~/modules/action'
import ViewOrchestrator from '~/components/ViewOrchestrator'
import ScrollWithEase from '~/components/ScrollWithEase'
import View from '~/components/View'
import Logo from '~/components/Logo'
import Loader from '~/components/Loader'
import Intro from '~/components/Intro'
import PixiStage from '~/components/PixiStage'
import View1 from '~/components/View1'
import View2 from '~/components/View2'
import View3 from '~/components/View3'
import Overlay from '~/components/Overlay'
import Background from '~/components/Background'
import SwitchView from '~/components/SwitchView'
import getScreenSize from '~/helpers/getScreenSize'
import EasterEgg from '~/components/EasterEgg'
import RotateYourDevice from '~/components/RotateYourDevice'
import loaderManager from '~/helpers/loaderManager'
import getQueryString from '~/helpers/getQueryString'
import gui from '~/helpers/gui'
import stats from '~/helpers/stats'

class Page extends React.Component {
  state = {
    isMobileDevice: false,
    loadingProgress: 0,
    hasLogo: true
  }

  componentDidMount () {
    const { isMobileDevice } = this.state

    this.initPreload()
    this.setMobileDevice()
    this.setDebugMode()
    this.setVersion()
    this.setLooks()
    this.setViewport()
    this.setViews()
    this.setLogo()

    const onResize = () => {
      this.setViewport()
      this.setVersion()
    }

    if (isMobileDevice) {
      window.addEventListener('orientationchange', () =>
        setTimeout(onResize, 500)
      )

      setTimeout(onResize, 400)
    } else {
      window.addEventListener('resize', onResize)
    }
  }

  static getDerivedStateFromProps (props) {
    if (typeof window === 'undefined') {
      return null
    }

    const md = new MobileDetect(window.navigator.userAgent)

    return {
      isMobileDevice: md.mobile(),
    }
  }

  initPreload () {
    const { setAppReady } = this.props

    const onProgress = progress => {
      this.setState({
        loadingProgress: progress
      })
    }

    setTimeout(() => {
      loaderManager.loading({
        onProgress,
        onComplete: setAppReady
      })
    }, 1000)
  }

  setMobileDevice () {
    const { isMobileDevice } = this.state
    const { setMobileDevice } = this.props

    setMobileDevice(isMobileDevice)
  }

  setDebugMode () {
    const { setDebugMode } = this.props

    const queryString = getQueryString(window.location.href)

    const debugMode = queryString.debug === 'true'

    setDebugMode(debugMode)

    debugMode && gui.init()
    debugMode && stats.init()
  }

  setVersion () {
    const { setVersion } = this.props

    if (window.innerWidth > 768) {
      setVersion('desktop')
    } else {
      setVersion('mobile')
    }
  }

  setLooks () {
    const { data, setLooks } = this.props
    const { looks } = data

    setLooks({ looks })
  }

  setViewport () {
    const { isMobileDevice } = this.state
    const { setViewport } = this.props

    const { width, height } = getScreenSize(isMobileDevice)

    // // Fix Safari Fullscreen Bug
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    // const width = isSafari ? Math.min(originalWidth, 1440) : originalWidth
    // const height = originalHeight * width / originalWidth

    setViewport({
      width,
      height,
    })
  }

  setViews () {
    const { data, setViews } = this.props
    const { views } = data

    setViews(views)
  }

  setLogo () {
    const queryString = getQueryString(window.location.href)

    const hasLogo = !(queryString.app === 'true')

    this.setState({ hasLogo })
  }

  render () {
    const { loadingProgress, hasLogo } = this.state
    const { data, views, version, activeView } = this.props

    if (!views || !version) {
      return <React.Fragment />
    }

    const {
      VIEW_1,
      VIEW_2,
      VIEW_3,
      VIEW_OVERLAY,
      VIEW_INTRO,
      VIEW_LOADER
    } = views

    const isMobile = version === 'mobile'
    const isDesktop = version === 'desktop'

    const isIntro = [VIEW_INTRO, VIEW_LOADER].indexOf(activeView.key) > -1

    return (
      <ScrollWithEase>
        <ViewOrchestrator>
          {hasLogo && <Logo />}

          <PixiStage>
            <View
              view={[VIEW_1, VIEW_2, VIEW_OVERLAY]}
              component={Background}
            />
            <View
              view={VIEW_1}
              forceMount={isDesktop && isIntro}
              component={View1}
              data={data.view1} />
          </PixiStage>

          <View
            view={VIEW_2}
            component={View2}
            data={data.view2} />

          <View
            view={VIEW_3}
            forceMount={isMobile && isIntro}
            component={View3} />

          <View
            view={[VIEW_1, VIEW_2, VIEW_3]}
            component={SwitchView} />

          <View
            view={VIEW_OVERLAY}
            component={Overlay} />

          <View
            view={VIEW_INTRO}
            forceMount={isIntro}
            component={Intro} />

          <View
            view={VIEW_LOADER}
            component={Loader}
            progress={loadingProgress} />

          <EasterEgg />

          <RotateYourDevice />
        </ViewOrchestrator>
      </ScrollWithEase>
    )
  }
}

const mapStateToProps = ({ views, version, activeView }) => ({
  views,
  version,
  activeView
})

const mapDispatchToProps = dispatch => ({
  setDebugMode: payload => dispatch(Actions.setDebugMode(payload)),
  setAppReady: payload => dispatch(Actions.setAppReady(payload)),
  setMobileDevice: payload => dispatch(Actions.setMobileDevice(payload)),
  setVersion: payload => dispatch(Actions.setVersion(payload)),
  setLooks: payload => dispatch(Actions.setLooks(payload)),
  setViewport: payload => dispatch(Actions.setViewport(payload)),
  setViews: payload => dispatch(Actions.setViews(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Page)
