import React from 'react'
import { connect } from 'react-redux'
import pressGKey from '~/media/icons/press-g-key.svg'
import tapAndHold from '~/media/icons/tap-and-hold.svg'

class EasterEgg extends React.Component {
  render () {
    const { isMobileDevice } = this.props

    const src = isMobileDevice ? tapAndHold : pressGKey

    return (
      <div className="wrapper">
        <img
          className="image"
          src={src}
          alt="" />

        <style jsx>{`
          .wrapper {
            position: absolute;
            left: 24%;
            bottom: 0;
            width: 30%;
            transform: rotate(15deg);
          }
          .image {
            width: 100%;
            vertical-align: middle;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ isMobileDevice }) => ({
  isMobileDevice,
})

export default connect(mapStateToProps)(EasterEgg)
