import React from 'react'
import { map } from 'lodash'
import Block from './Block'

class Group extends React.Component {
  render () {
    const { blocks } = this.props

    const blockNodes = map(blocks, data => {
      const { id } = data

      return <Block
        key={id}
        data={data} />
    })

    return (
      <div className="wrapper">
        {blockNodes}

        <style jsx>{`
          .wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            align-content: baseline;
            width: 100%;
          }
          @media (min-width: 960px) {
            .wrapper {
              width: 50%;
              margin-left: -1px;
            }
          }
        `}</style>
      </div>
    )
  }
}

export default Group
