import React from 'react'
import { connect } from 'react-redux'
import { isFunction, isArray } from 'lodash'
import Queues from '~/helpers/Queues'

const queues = Queues()

class View extends React.Component {
  state = {
    forceMount: false,
    isActive: false,
  }

  wrapper = React.createRef()

  enter = null
  leave = null

  constructor () {
    super()

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.onEnter = this.onEnter.bind(this)
    this.onLeave = this.onLeave.bind(this)
  }

  componentDidMount () {
    const { activeView, forceMount } = this.props

    this.toggle(activeView)
    this.forceMount(forceMount)
  }

  componentDidUpdate (prevProps) {
    const { activeView, forceMount } = this.props

    if (prevProps.activeView.key !== activeView.key) {
      this.toggle(activeView)
    }

    if (prevProps.forceMount !== forceMount) {
      this.forceMount(forceMount)
    }
  }

  onEnter (animation) {
    this.enter = animation
  }

  onLeave (animation) {
    this.leave = animation
  }

  forceMount (forceMount) {
    this.setState({ forceMount })
  }

  toggle (activeView) {
    const { view } = this.props
    const { isActive } = this.state

    const toggle = callback => {
      this.setState({ isActive: !isActive }, callback)
    }

    const isCurrent = view => {
      if (isArray(view)) {
        return view.indexOf(activeView.key) > -1
      } else {
        return activeView.key === view
      }
    }

    if (isCurrent(view)) {
      if (!isActive) {
        // entering view
        queues.add(done => {
          toggle(() => this.show(done))
        }, 1)
      }
    } else {
      if (isActive) {
        // entering view
        queues.add(done => {
          this.hide(() => toggle(done))
        }, 2)
      }
    }
  }

  show (done) {
    window.requestAnimationFrame(() => {
      isFunction(this.enter) ? this.enter(done) : done()
    })
  }

  hide (done) {
    window.requestAnimationFrame(() => {
      isFunction(this.leave) ? this.leave(done) : done()
    })
  }

  render () {
    const { activeView, component: Component } = this.props
    const { isActive, forceMount } = this.state

    return isActive || forceMount ? (
      <Component
        config={activeView}
        onEnter={this.onEnter}
        onLeave={this.onLeave}
        {...this.props}
      />
    ) : (
      <React.Fragment />
    )
  }
}

const mapStateToProps = ({ activeView }) => ({
  activeView,
})

export default connect(mapStateToProps)(View)
