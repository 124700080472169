import * as dat from 'dat.gui'

let gui

const init = () => {
  if (!gui) {
    gui = new dat.GUI({ width: 300 })
  }
}

export default {
  init,
  get: callback => {
    if (gui) {
      callback(gui)
    }
  },
}
