import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import Content from './Content'
import scrollWithEase from '~/helpers/scrollWithEase'
import { TweenMax } from 'gsap'

class View3 extends React.Component {
  wrapper = React.createRef()

  componentDidMount () {
    this.handleScroll()
    this.setOnEnter()
    this.setOnLeave()
  }

  handleScroll () {
    const { config } = this.props

    if (!config.look) {
      let scrollTo = 0

      if (config.scroll) {
        scrollTo = config.scroll
      }

      scrollWithEase.reset(scrollTo)
    }
  }

  setOnEnter () {
    const { onEnter } = this.props
    const { current: elm } = this.wrapper

    TweenMax.set(elm, { opacity: 0 })

    onEnter(onComplete => {
      TweenMax.to(elm, 0.5, {
        opacity: 1,
        ease: 'Sine.easeOut',
        onComplete,
      })
    })
  }

  setOnLeave () {
    const { onLeave } = this.props
    const { current: elm } = this.wrapper

    onLeave(onComplete => {
      TweenMax.to(elm, 0.5, {
        opacity: 0,
        ease: 'Sine.easeIn',
        onComplete,
      })
    })
  }

  render () {
    const { looks, config } = this.props

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        {looks && <Content
          data={looks}
          currentLook={config.look} />}

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            overflow: hidden;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ looks }) => ({
  looks,
})

const mapDispatchToProps = dispatch => ({
  setBody: payload => dispatch(Actions.setBody(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(View3)
