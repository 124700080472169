import getOrientation from './getOrientation'

export default function (isMobileDevice) {
  const viewport = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  const source = isMobileDevice ? window.screen : viewport

  let { width, height } = source

  if (isMobileDevice) {
    const orientation = getOrientation()

    if (orientation === 'landscape') {
      if (height > width) {
        const tmp = width
        width = height
        height = tmp
      }
    }
  }

  return { width, height }
}
