import {
  get,
  toArray,
  groupBy,
  flatten,
  first,
  orderBy,
  debounce
} from 'lodash'

const Queues = (max = 0) => {
  const _max = max
  let _fs = []
  let _busy = false

  const _add = (callback, priority) => {
    if (typeof callback === 'function') {
      if (!priority) {
        priority = _fs.length ? get(first(first(_fs)), 'priority') + 1 : 1
      }

      const payload = {
        priority,
        callback,
      }

      _fs.push(payload)

      _fs = toArray(groupBy(flatten(_fs), 'priority'))

      _fs = orderBy(_fs, item => get(first(item), 'priority'), 'desc')

      if (max) {
        _fs = _fs.slice(0, _max)
      }

      _execute()
    }
  }

  const _execute = debounce(() => {
    if (!_busy) {
      const items = _fs.shift()

      if (items) {
        _busy = true

        const done = () => {
          _busy = false
          _execute()
        }

        const promises = items.map(payload => {
          return new Promise(resolve => {
            payload.callback(resolve)
          })
        })

        Promise.all(promises).then(done)
      }
    }
  })

  return {
    add: _add,
  }
}

export default Queues
