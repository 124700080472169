import React from 'react'
import { flowRight, map } from 'lodash'
import { connect } from 'react-redux'
import { withPixiApp, Container, Sprite } from '@inlet/react-pixi'
import scrollWithEase from '~/helpers/scrollWithEase'
import Link from './Link'
import Block from './Block'
import * as PIXI from 'pixi.js'

class Content extends React.Component {
  state = {
    scroll: {},
  }

  constructor () {
    super()

    this.setScroll = this.setScroll.bind(this)
  }

  componentDidMount () {
    scrollWithEase.get(this.setScroll)
    scrollWithEase.on('scroll', this.setScroll)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setScroll)
  }

  setScroll (scroll) {
    this.setState({ scroll })
  }

  render () {
    const { scroll } = this.state
    const { data, body } = this.props
    const { width, height } = body

    const blockNodes = map(data.blocks, data => {
      return <Block
        key={data.id}
        priority={data.y}
        data={data} />
    })

    const linksNodes = map(data.links, data => {
      return (
        <Link
          key={data.id}
          y={data.y}
          label={data.label}
          link={data.link}
          target={data.target}
        />
      )
    })

    const area = (
      <Sprite
        texture={PIXI.Texture.EMPTY}
        width={width}
        height={height} />
    )

    return (
      <Container y={-scroll.value}>
        {area}
        {blockNodes}
        {linksNodes}
      </Container>
    )
  }
}

const mapStateToProps = ({ body }) => ({
  body,
})

export default flowRight(
  connect(mapStateToProps),
  withPixiApp || Content
)(Content)
