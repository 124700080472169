import { TextureLoader, RepeatWrapping } from 'three'
import { loaderManager } from './loadingManager'

const loader = new TextureLoader(loaderManager)

const createTexture = function (src) {
  const txt = loader.load(require(`~/${ src }`))
  txt.wrapS = txt.wrapT = RepeatWrapping
  return txt
}

export { loader, createTexture }
