import openLink from '~/helpers/openLink'

export default function ({ element, callback, link, target, isActive = true }) {
  const nav = () => {
    if (isActive) {
      if (typeof callback === 'function') {
        callback()
      } else {
        openLink(link, target)
      }
    }
  }

  if (element) {
    if ('ontouchstart' in document.documentElement) {
      let start = 0

      element.on('pointerdown', () => {
        start = Date.now()
      })

      element.on('pointerup', () => {
        const now = Date.now()

        if (start && now - start > 60 && now - start < 250) {
          nav()
        }
      })

      element.on('touchmove', () => {
        start = 0
      })
    } else {
      element.on('click', nav)
    }

    element.cursor = 'hover'
  }
}
