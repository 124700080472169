import React from 'react'
import { flowRight } from 'lodash'
import { Provider, connect } from 'react-redux'
import store from '~/modules/store'
import { Sprite, Container, render } from '@inlet/react-pixi'
import * as PIXI from 'pixi.js'
import pixiTicker from '~/hoc/pixiTicker'
import NormalContent from './NormalContent'
import TrailContent from './TrailContent'

let PIXIContainer

class Background extends React.Component {
  container = React.createRef()
  content = React.createRef()

  trailTexture = PIXI.RenderTexture.create()

  componentDidMount () {
    this.initBackgroundFilter()
    this.initTrailContentFilter()
    this.initTrailTexture()
  }

  componentWillUnmount () {}

  initBackgroundFilter () {
    const { app, addToTicker } = this.props
    const { renderer } = app

    const frag = require('~/glsl/background.frag')

    const uniforms = {
      uResolution: {
        type: 'v2',
        value: [0, 0],
      },
    }

    const shader = new PIXI.Filter('', frag, uniforms)

    shader.padding = 0

    this.container.current.filters = [shader]

    addToTicker(() => {
      const { uniforms } = shader

      uniforms.uResolution[0] = renderer.width
      uniforms.uResolution[1] = renderer.height
    })
  }

  initTrailContentFilter () {
    const { app, addToTicker } = this.props
    const { renderer } = app

    const fragBulb = require('~/glsl/bulb.frag')
    const fragGlow = require('~/glsl/glow.frag')

    const uniforms = {
      uResolution: {
        type: 'v2',
        value: [0, 0],
      },
    }

    const shaderBulb = new PIXI.Filter('', fragBulb, uniforms)
    const shaderGlow = new PIXI.Filter('', fragGlow, uniforms)

    shaderBulb.padding = 0
    shaderGlow.padding = 0

    this.content.current.filters = [shaderBulb, shaderGlow]

    addToTicker(() => {
      shaderBulb.uniforms.uResolution[0] = renderer.width
      shaderBulb.uniforms.uResolution[1] = renderer.height
      shaderGlow.uniforms.uResolution[0] = renderer.width
      shaderGlow.uniforms.uResolution[1] = renderer.height
    })
  }

  initTrailTexture () {
    const { app, viewport, addToTicker } = this.props
    const { width, height } = viewport

    const rect = new PIXI.Graphics()

    rect.beginFill(0x000000)
    rect.drawRect(0, 0, width, height)
    rect.endFill()
    rect.alpha = 0.1

    this.clearBackground = new PIXI.Sprite(rect.generateCanvasTexture())

    if (!PIXIContainer) {
      PIXIContainer = new PIXI.Container()

      const component = (
        <Provider store={store}>
          <TrailContent
            app={app}
            addToTicker={addToTicker} />
        </Provider>
      )

      render(component, PIXIContainer)
    }

    addToTicker(() => {
      app.renderer.render(this.clearBackground, this.trailTexture, false)
      app.renderer.render(PIXIContainer, this.trailTexture, false)
    })
  }

  resizeRenderTexture () {
    const { viewport } = this.props
    const { width, height } = viewport

    if (this.clearBackground) {
      this.clearBackground.width = width
      this.clearBackground.height = height
    }

    this.trailTexture.resize(width, height)
  }

  render () {
    const { viewport } = this.props
    const { width, height } = viewport

    this.resizeRenderTexture()

    return (
      <Container ref={this.container}>
        <Container ref={this.content}>
          <Sprite
            texture={this.trailTexture}
            width={width}
            height={height} />
          <NormalContent />
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = ({ body, viewport }) => ({
  body,
  viewport,
})

export default flowRight(
  connect(mapStateToProps),
  pixiTicker
)(Background)
