import React from 'react'

class Button extends React.Component {
  render () {
    const { onClick, svg, isActive } = this.props

    return (
      <div
        className="wrapper"
        onClick={onClick}>
        <img
          className="image"
          src={svg}
          alt="" />

        <style jsx>{`
          .wrapper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            margin: 0 10px;
            cursor: pointer;
            user-select: none;
          }
          .wrapper:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid white;
            background: rgba(0, 0, 0, 0.8);
            opacity: 0.37;
            opacity: scale(${ isActive ? 1 : 0 });
            transform: scale(${ isActive ? 1 : 0 });
            transition: 0.2s transform ease-in-out, 0.2s opacity ease-in-out;
          }
          .image {
            z-index: 2;
            position: relative;
            width: 20px;
          }
        `}</style>
      </div>
    )
  }
}
export default Button
