import React from 'react'
import BaseLayout from '~/layouts/base'
import Page from '~/components/Page'
import { woman } from '~/data'

class WomanPage extends React.Component {
  render () {
    return (
      <BaseLayout>
        <Page data={woman} />
      </BaseLayout>
    )
  }
}

export default WomanPage
