import React from 'react'
import { flowRight } from 'lodash'
import { Provider, connect } from 'react-redux'
import { Stage } from '@inlet/react-pixi'
import store from '~/modules/store'
import renderIfClientHoc from '~/hoc/renderIfClientHoc'

class PixiStage extends React.Component {
  stage = React.createRef()

  componentDidUpdate (prevProps) {
    const { isAppReady, easterEgg } = this.props

    if (prevProps.isAppReady !== isAppReady) {
      const { app } = this.stage.current

      app.renderer.plugins.interaction.autoPreventDefault = false
      app.renderer.view.style.touchAction = 'auto'
      app.renderer.plugins.interaction.cursorStyles.hover = 'pointer'
    }

    if (prevProps.easterEgg !== easterEgg) {
      if (easterEgg) {
        this.stop()
      } else {
        this.play()
      }
    }
  }

  play () {
    const { app } = this.stage.current

    app.start()
  }

  stop () {
    const { app } = this.stage.current

    app.stop()
  }

  render () {
    const { children, viewport } = this.props
    const { width, height } = viewport

    const props = {
      width: width,
      height: height,
      options: {
        antialias: true,
        transparent: true,
        autoResize: false,
        legacy: true,
        resolution: 1, // window.devicePixelRatio,
      },
    }

    return (
      <div className="wrapper">
        <Stage
          ref={this.stage}
          {...props}>
          <Provider store={store}>{children}</Provider>
        </Stage>

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            overflow: hidden;
          }
          .wrapper :global(canvas) {
            width: 100%;
            height: 100%;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ viewport, isAppReady, easterEgg }) => ({
  viewport,
  isAppReady,
  easterEgg,
})

export default flowRight(
  connect(mapStateToProps),
  renderIfClientHoc
)(PixiStage)
