import EventEmitter from 'events'
import Draggable from 'gsap/Draggable'

const Swap = el => {
  const events = new EventEmitter()

  const div = document.createElement('div')

  const draggable = Draggable.create(div, {
    cursor: 'default',
    trigger: el,
    throwProps: true,
    edgeResistance: 1,
    dragClickables: true,
  })[0]

  const getShift = () => ({
    x: draggable.x - draggable.startX,
    y: draggable.y - draggable.startY,
  })

  const enable = () => {
    draggable.enable()
  }

  const disable = () => {
    draggable.disable()
  }

  const on = (eventName, cb) => {
    events.on(eventName, cb)
  }

  draggable.addEventListener('drag', () => {
    const shift = getShift()

    events.emit('drag', shift.x, shift.y)
  })

  draggable.addEventListener('dragend', () => {
    const shift = getShift()

    events.emit('dragend', shift.x, shift.y)
  })

  disable()

  return {
    enable,
    disable,
    on,
  }
}

export default Swap
