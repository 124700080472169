import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import { withPixiApp, Container, Sprite, Text } from '@inlet/react-pixi'
import { Actions } from '~/modules/action'
import pixiBoundsHoc from '~/hoc/pixiBoundsHoc'
import pixiScrollObserverHoc from '~/hoc/pixiScrollObserverHoc'
import pixiImageHoc from '~/hoc/pixiImageHoc'
import triggersManagerHoc from '~/hoc/triggersManagerHoc'
import scrollWithEase from '~/helpers/scrollWithEase'
import ObjectFit from '~/helpers/ObjectFit'
import pixiLink from '~/helpers/pixiLink'
import * as PIXI from 'pixi.js'

class Block extends React.Component {
  container = React.createRef()
  sprite = React.createRef()

  constructor () {
    super()

    this.setShift = this.setShift.bind(this)
    this.forceUpdate = this.forceUpdate.bind(this)

    this.objectFit = ObjectFit({
      behavior: 'fit',
    })
  }

  componentDidMount () {
    this.initLink()

    setTimeout(this.setShift)
    setTimeout(this.forceUpdate)

    scrollWithEase.on('scroll', this.setShift)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setShift)
  }

  setShift () {
    const { data, bounds, progress, body } = this.props
    const { acceleration } = data
    const { scaleFactor } = body

    if (acceleration) {
      const shift = (progress.value - 0.5) * (acceleration * scaleFactor)
      const { y } = bounds

      this.container.current.position.y = y - shift
    }
  }

  initLink () {
    const { data, openLook, easterEgg } = this.props

    const callback = () => {
      openLook(data.overlay)
    }

    if (data.overlay) {
      const { current: element } = this.sprite
      const isActive = !easterEgg

      pixiLink({
        element,
        callback,
        isActive,
      })
    }
  }

  get texture () {
    const { app, bounds, texture, imageSize, ready } = this.props

    this.objectFit.set('elm', imageSize)
    this.objectFit.set('parent', bounds)
    this.objectFit.set('scale', 1)

    const renderTexture = PIXI.RenderTexture.create(bounds.width, bounds.height)

    const graphics = new PIXI.Graphics()

    graphics.beginFill(0x11110f)
    graphics.drawRect(0, 0, bounds.width, bounds.height)
    graphics.endFill()

    const background = new PIXI.Sprite(graphics.generateCanvasTexture())

    const sprite = new PIXI.Sprite(texture)

    if (!ready) {
      const blur = new PIXI.filters.BlurFilter(8)

      sprite.filters = [blur]
      sprite.alpha = 0.3

      this.objectFit.set('scale', 1.1)
    }

    const { values: fit } = this.objectFit

    sprite.position.set(fit.x, fit.y)
    sprite.width = fit.width
    sprite.height = fit.height

    app.renderer.render(background, renderTexture)
    app.renderer.render(sprite, renderTexture, false)

    return renderTexture
  }

  render () {
    const { bounds, debugMode, data } = this.props
    const { x, y, width, height } = bounds

    return (
      <Container
        ref={this.container}
        x={x}
        y={y}>
        <Sprite
          ref={this.sprite}
          texture={this.texture}
          width={width}
          height={height}
          interactive={true}
        />
        {debugMode && (
          <Text
            text={data.id}
            zIndex={999}
            style={
              new PIXI.TextStyle({
                fill: '#00ff00',
              })
            }
          />
        )}
      </Container>
    )
  }
}

const mapStateToProps = ({ body, easterEgg, debugMode }) => ({
  body,
  easterEgg,
  debugMode,
})

const mapDispatchToProps = dispatch => ({
  openLook: payload => dispatch(Actions.openLook(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  pixiBoundsHoc,
  pixiScrollObserverHoc,
  pixiImageHoc,
  triggersManagerHoc,
  withPixiApp || Block
)(Block)
