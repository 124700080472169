export default function () {
  const orientationRaw =
    window.screen.msOrientation ||
    window.screen.mozOrientation ||
    (window.screen.orientation || {}).type

  let orientation = 'portrait'

  if (
    (orientationRaw && orientationRaw.indexOf('landscape') > -1) ||
    Math.abs(window.orientation) === 90
  ) {
    orientation = 'landscape'
  }

  return orientation
}
