import React from 'react'
import { flowRight, get } from 'lodash'
import { connect } from 'react-redux'
import { Sprite } from '@inlet/react-pixi'
import pixiTicker from '~/hoc/pixiTicker'
import image from '~/media/other/blinding.jpg'
import { TimelineMax } from 'gsap'
import * as PIXI from 'pixi.js'
import Queues from '~/helpers/Queues'

class Blinding extends React.Component {
  sprite = React.createRef()
  progress = 0

  queues = Queues(1)

  componentDidMount () {
    this.initFilter()
  }

  componentDidUpdate (prevProps) {
    const { trigger } = this.props

    if (prevProps.trigger !== trigger) {
      this.triggerEffect()
    }
  }

  triggerEffect () {
    const { trigger } = this.props

    if (trigger) {
      this.queues.add(done => this.play(done))
    }
  }

  initFilter () {
    const { addToTicker } = this.props

    const frag = require('~/glsl/blinding.frag')

    const uniforms = {
      uProgress: {
        type: 'f',
        value: 0,
      },
    }

    const shader = new PIXI.Filter('', frag, uniforms)

    shader.padding = 0

    this.sprite.current.filters = [shader]

    addToTicker(() => {
      const { uniforms } = shader

      uniforms.uProgress = this.progress
    })
  }

  play (onComplete) {
    const timeline = new TimelineMax({ onComplete })

    timeline
      .to(this, 0.5, {
        progress: 1,
        ease: 'Power2.easeIn',
      })
      .to(this, 5, {
        progress: 0,
        delay: 0.3,
        ease: 'Power1.easeOut',
      })
  }

  render () {
    const { viewport } = this.props
    const { width, height } = viewport

    return (
      <Sprite
        ref={this.sprite}
        image={image}
        width={width}
        height={height} />
    )
  }
}

const mapStateToProps = ({ viewport, triggers }) => ({
  viewport,
  trigger: get(triggers, 'blinding', null),
})

export default flowRight(
  connect(mapStateToProps),
  pixiTicker
)(Blinding)
