import React from 'react'
import { flowRight, max, map } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import { Container } from '@inlet/react-pixi'
import Content from './Content'
import scrollWithEase from '~/helpers/scrollWithEase'
import { TweenMax } from 'gsap'

class View1 extends React.Component {
  wrapper = React.createRef()

  componentDidMount () {
    this.handleScroll()
    this.setOnEnter()
    this.setOnLeave()
    this.setView1()
    this.setBody()
  }

  componentDidUpdate (prevProps) {
    const { viewport, view1 } = this.props

    if (prevProps.viewport !== viewport) {
      this.setBody()
    }

    if (prevProps.view1 !== view1) {
      this.setBody()
    }
  }

  handleScroll () {
    const { config } = this.props

    let scrollTo = 0

    if (config.scroll) {
      scrollTo = config.scroll
    }

    scrollWithEase.reset(scrollTo)
  }

  setOnEnter () {
    const { onEnter } = this.props
    const { current: elm } = this.wrapper

    elm.alpha = 0
    elm.y = 30

    onEnter(onComplete => {
      TweenMax.to(elm, 0.5, {
        alpha: 1,
        y: 0,
        ease: 'Sine.easeOut',
        onComplete,
      })
    })
  }

  setOnLeave () {
    const { onLeave } = this.props
    const { current: elm } = this.wrapper

    onLeave(onComplete => {
      TweenMax.to(elm, 0.5, {
        alpha: 0,
        y: 30,
        ease: 'Sine.easeIn',
        onComplete,
      })
    })
  }

  setView1 () {
    const { data, view1, setView1 } = this.props

    if (view1) {
      return
    }

    const { width, marquees, links, blocks } = data

    const height = max(map(blocks, item => item.y + item.height))

    setView1({
      width,
      height,
      marquees,
      links,
      blocks,
    })
  }

  setBody () {
    const { view1, viewport, setBody } = this.props

    if (!view1) {
      return
    }

    const { width } = viewport
    const scaleFactor = width / view1.width
    const marginBottom = 100
    const height = view1.height * scaleFactor + marginBottom

    setBody({
      width,
      height,
      scaleFactor,
    })
  }

  render () {
    const { view1, view } = this.props

    const scroll = view.scroll || 0

    return (
      <Container ref={this.wrapper}>
        {view1 && <Content
          data={view1}
          scroll={scroll} />}
      </Container>
    )
  }
}

const mapStateToProps = ({ view1, viewport }) => ({
  view1,
  viewport,
})

const mapDispatchToProps = dispatch => ({
  setView1: payload => dispatch(Actions.setView1(payload)),
  setBody: payload => dispatch(Actions.setBody(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(View1)
