import React from 'react'
import { get, map, concat } from 'lodash'
import Look from './Look'
import openLink from '~/helpers/openLink'

class Group extends React.Component {
  wrapper = React.createRef()

  constructor () {
    super()

    this.openLink = this.openLink.bind(this)
  }

  openLink () {
    const { data } = this.props
    const { link } = data

    openLink(link, '_blank')
  }

  render () {
    const { data, currentLook } = this.props

    const closeups = get(data, 'closeups', [])
    const items = concat(data, closeups)

    const lookNodes = map(items, data => {
      const { id } = data

      return <Look
        key={id}
        isCurrent={currentLook === id}
        data={data} />
    })

    return (
      <div
        className="wrapper"
        onClick={this.openLink}>
        >{lookNodes}
        <style jsx>{`
          .wrapper {
            width: 100%;
          }
        `}</style>
      </div>
    )
  }
}

export default Group
