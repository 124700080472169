import React from 'react'
import { connect } from 'react-redux'
import loaderManager from '~/helpers/loaderManager'
import { uniqueId } from 'lodash'

class Image extends React.Component {
  state = {
    ready: false,
    active: false,
    animated: false,
    width: 0,
    height: 0,
    srcSet: '',
    placeholder: '',
  }

  wrapper = React.createRef()
  intersection = React.createRef()
  image = React.createRef()

  constructor () {
    super()

    this.preload = this.preload.bind(this)
    this.makeReady = this.makeReady.bind(this)
  }

  static getDerivedStateFromProps (props) {
    const { path } = props

    return require(`~/${ path }`)
  }

  componentDidMount () {
    const { lazyload, preload } = this.props

    this.init()

    if (lazyload) {
      this.lazyload(() => this.preload(this.makeReady))
    } else if (preload) {
      this.preload(this.makeReady)
    } else {
      this.makeReady()
    }
  }

  init () {
    const { path } = this.props

    const src = require(`~/${ path }`)

    this.setState({ ...src })
  }

  lazyload (cb) {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      const observer = new window.IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const { isIntersecting } = entry

            if (isIntersecting) {
              cb()
              observer.disconnect()
            }
          })
        },
        {
          threshold: 0,
        }
      )

      observer.observe(this.intersection.current)
    } else {
      cb()
    }
  }

  preload (cb) {
    this.setState({
      active: true,
      animated: false,
    })

    const loader = loaderManager.add({ id: uniqueId('image') })

    const start = new Date()

    this.image.current.onload = () => {
      const now = new Date()

      loader.resolve()

      if (now - start > 100) {
        this.setState({ animated: true }, cb)
      } else {
        cb()
      }
    }
  }

  makeReady () {
    this.setState({
      active: true,
      ready: true,
    })
  }

  render () {
    const { sizes, isMobileDevice } = this.props

    const { ready, active, animated, width, height, srcSet } = this.state

    const ratio = `${ (height / width) * 100 }%`

    const hasTransition = !isMobileDevice && animated

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        <div
          ref={this.intersection}
          className="intersection" />
        <img
          ref={this.image}
          alt=""
          className="image"
          srcSet={active ? srcSet : ''}
          sizes={sizes}
        />

        <style jsx>{`
          .wrapper {
            position: relative;
            width: 100%;
            padding-bottom: ${ ratio };
            background: #11110f;
          }
          .intersection {
            position: absolute;
            display: ${ ready ? 'none' : 'block' };
            bottom: 0;
            left: 0;
            width: 100%;
            height: 300%;
            pointer-events: none;
          }
          .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            opacity: ${ ready ? 1 : 0 };
            transition: ${ hasTransition ? 'opacity 0.3s ease-in-out' : 'none' };
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ isMobileDevice }) => ({
  isMobileDevice,
})

export default connect(mapStateToProps)(Image)
