import { find, sortBy } from 'lodash'

class Srcset {
  constructor ({ images }) {
    this.loaded = []
    this.images = sortBy(images, image => image.width)
  }

  get bigger () {
    return this.images[this.images.length - 1]
  }

  find (dataset, width) {
    return find(dataset, image => {
      return image.width > width
    })
  }

  get (width) {
    const image =
      this.find(this.loaded, width) ||
      this.find(this.images, width) ||
      this.bigger

    this.loaded.push(image)

    return image
  }
}

export default Srcset
