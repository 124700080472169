import React from 'react'
import { flowRight, find, indexOf, first, last } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import scrollWithEase from '~/helpers/scrollWithEase'
import Back from './Back'
import Look from './Look'
import Nav from './Nav'
import Link from './Link'
import { TweenMax } from 'gsap'

class Overlay extends React.Component {
  state = {
    look: null,
    previous: null,
    next: null,
    scroll: {},
    direction: 0,
  }

  wrapper = React.createRef()

  constructor () {
    super()

    this.setScroll = this.setScroll.bind(this)
    this.resetScroll = this.resetScroll.bind(this)
  }

  componentDidMount () {
    const { overlay } = this.props

    scrollWithEase.on('scroll', this.setScroll)

    this.resetScroll()
    this.setOnEnter()
    this.setOnLeave()

    this.setLook(overlay)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setScroll)
  }

  componentDidUpdate (prevProps, prevState) {
    const { overlay } = this.props

    if (prevProps.overlay !== overlay) {
      this.setLook(overlay)
    }
  }

  setOnEnter () {
    const { onEnter } = this.props
    const { current: elm } = this.wrapper

    TweenMax.set(elm, { opacity: 0, scale: 1.02, y: 10 })

    onEnter(onComplete => {
      TweenMax.to(elm, 0.5, {
        opacity: 1,
        scale: 1,
        y: 0,
        ease: 'Sine.easeOut',
        onComplete,
      })
    })
  }

  setOnLeave () {
    const { onLeave } = this.props
    const { current: elm } = this.wrapper

    onLeave(onComplete => {
      TweenMax.to(elm, 0.5, {
        opacity: 0,
        scale: 1.02,
        y: 10,
        ease: 'Sine.easeIn',
        onComplete,
      })
    })
  }

  setScroll (scroll) {
    this.setState({ scroll })
  }

  setLook (id) {
    const { looks } = this.props

    const look = find(looks, { id })

    if (look) {
      const index = indexOf(looks, look)

      const previous = looks[index - 1] || last(looks)
      const next = looks[index + 1] || first(looks)

      this.setState({
        look,
        previous,
        next,
      })
    }
  }

  resetScroll () {
    scrollWithEase.reset()
  }

  change (look, direction) {
    const { setOverlay } = this.props

    if ('id' in look) {
      setOverlay(look.id)

      this.setState({
        direction,
      })
    }
  }

  render () {
    const { look, previous, next, scroll, direction } = this.state

    const navPrev = () => this.change(previous, -1)
    const navNext = () => this.change(next, 1)

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        <div>
          <Back />

          <div className="content">
            <Look
              look={look}
              resetScroll={this.resetScroll} />
          </div>

          <div className="previous">
            <Nav
              look={previous}
              label="Previous"
              type="prev"
              delay={0.1}
              direction={direction}
              onClick={navPrev}
            />
          </div>

          <div className="next">
            <Nav
              look={next}
              label="Next"
              type="next"
              delay={0}
              direction={direction}
              onClick={navNext}
            />
          </div>

          <div className="button">
            <Link look={look} />
          </div>
        </div>

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: #11110f;
          }
          .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100%;
            overflow: hidden;
            transform: translateY(${ -scroll.value }px);
          }
          .previous {
            position: absolute;
            top: 18vh;
            left: 9.8vw;
            transform: translateY(${ scroll.value * 0.06 }px);
          }
          .next {
            position: absolute;
            top: 35vh;
            right: 9.8vw;
            transform: translateY(${ -scroll.value * 0.06 }px);
          }
          .button {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 80px 0 38px 0;
            background: linear-gradient(
              rgba(17, 17, 15, 0),
              rgba(17, 17, 15, 0.38)
            );
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ viewport, looks, overlay }) => ({
  viewport,
  looks,
  overlay,
})

const mapDispatchToProps = dispatch => ({
  setBody: payload => dispatch(Actions.setBody(payload)),
  setOverlay: payload => dispatch(Actions.setOverlay(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Overlay)
