import React from 'react'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import scrollWithEase from '~/helpers/scrollWithEase'

function triggersManagerHoc (WrappedComponent) {
  class TriggersManagerHoc extends React.Component {
    constructor () {
      super()

      this.toggleTrigger = this.toggleTrigger.bind(this)
    }

    componentDidMount () {
      this.toggleTrigger()

      setTimeout(this.toggleTrigger)

      scrollWithEase.on('scroll', this.toggleTrigger)
    }

    componentWillUnmount () {
      scrollWithEase.off('scroll', this.toggleTrigger)
    }

    toggleTrigger () {
      const { data, progress, fireTrigger, priority } = this.props

      if (data.triggers) {
        let i = data.triggers.length

        while (i--) {
          const trigger = data.triggers[i]

          const { breakpoint, type, value } = trigger

          const hasEnter = progress.value > breakpoint

          if (hasEnter) {
            fireTrigger({
              id: data.id,
              type,
              priority,
              value,
            })
          }
        }
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapDispatchToProps = dispatch => ({
    fireTrigger: payload => dispatch(Actions.fireTrigger(payload)),
  })

  return connect(
    null,
    mapDispatchToProps
  )(TriggersManagerHoc)
}

export default triggersManagerHoc
