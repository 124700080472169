import React from 'react'
import { TweenMax, TimelineMax } from 'gsap'
import text from '~/media/loader/text.png'
import top from '~/media/loader/top.png'
import bottom from '~/media/loader/bottom.png'

class Loader extends React.Component {
  wrapper = React.createRef()
  topCloud = React.createRef()
  text = React.createRef()
  bottomCloud = React.createRef()

  componentDidMount () {
    this.setOnEnter()
    this.setOnLeave()
    this.initAnimation()
  }

  setOnEnter () {
    const loadImage = src => {
      const img = new window.Image()

      img.src = src

      return new Promise(resolve => {
        if (img.complete) {
          resolve()
        } else {
          img.addEventListener('load', resolve)
        }
      })
    }

    Promise.all([loadImage(top), loadImage(text), loadImage(bottom)]).then(
      () => {
        setTimeout(() => {
          TweenMax.to(this.wrapper.current, 2, { opacity: 1 })
        })
      }
    )
  }

  componentWillUnmount () {
    this.topAnimation.kill()
    this.bottomAnimation.kill()
  }

  setOnLeave () {
    const { onLeave } = this.props
    const { current: elm } = this.wrapper

    onLeave(onComplete => {
      TweenMax.to(elm, 0.3, {
        opacity: 0,
        delay: 2.2,
        ease: 'Sine.easeIn',
        onComplete,
      })
    })
  }

  initAnimation () {
    const { current: top } = this.topCloud
    const { current: bottom } = this.bottomCloud

    this.topAnimation = this.createTimelineClouds(top, -1)
    this.bottomAnimation = this.createTimelineClouds(bottom, 1)
  }

  createTimelineClouds (elm, dir) {
    const tlClouds = new TimelineMax({
      repeat: -1,
      delay: 0.2,
    })

    tlClouds
      // line
      .to(elm, 2.5, { y: `${ dir * 10 }%` })
      .to(elm, 2.5, { y: `${ dir * 5 }%` })

    return tlClouds
  }

  render () {
    const { progress } = this.props
    const width = `${ progress * 100 }%`

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        <div
          ref={this.topCloud}
          className="top" />
        <div className="line">
          <div
            className="text"
            ref={this.text} />
        </div>
        <div
          ref={this.bottomCloud}
          className="bottom" />

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            background: rgb(242, 228, 216);
            opacity: 0;
          }

          .top-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
          }
          .bottom-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
          }
          .text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            background-image: url("${ text }");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 90% auto;

          }
          .line {
            width: ${ width };
            height: 300px;
            transition: 0.4s width;
            position: relative;
            overflow: hidden;
            transition: 0.3s width ease-in-out;
          }

          .top {
            position: absolute;
            top: -5vh;
            right: 0;
            width: 100vw;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            background-image: url("${ top }");
            background-repeat: no-repeat;
            background-position: top;
            background-size: 100% auto;
            transform: translateY(-5%);
          }
          .bottom {
            position: absolute;
            bottom: -5vh;
            left: 0;
            width: 100vw;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow: hidden;
            background-image: url("${ bottom }");
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: 100% auto;
            transform: translateY(5%);
          }

          @media (min-width: 320px) {
            .top { top: 0 }
            .bottom { bottom: 0 }
          }
        `}</style>
      </div>
    )
  }
}

export default Loader
