import React from 'react'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import { TweenMax } from 'gsap'

class Intro extends React.Component {
  wrapper = React.createRef()
  video = React.createRef()
  discover = React.createRef()
  canLeave = false

  constructor () {
    super()

    this.close = this.close.bind(this)
  }

  componentDidMount () {
    window.addEventListener('mousewheel', this.close)

    this.wrapper.current.addEventListener('touchmove', this.close)

    this.setOnEnter()
    this.setOnLeave()
  }

  componentWillUnmount () {
    window.removeEventListener('mousewheel', this.close)

    this.wrapper.current.removeEventListener('touchmove', this.close)
  }

  setOnEnter () {
    const { onEnter } = this.props
    const { current: elm } = this.wrapper
    const { current: discover } = this.discover

    TweenMax.set(discover, {
      y: 10,
      opacity: 0,
    })

    TweenMax.set(elm, {
      opacity: 0,
    })

    onEnter(onComplete => {
      this.play()

      TweenMax.to(elm, 0.4, {
        opacity: 1,
        ease: 'Sine.easeIn',
        onComplete: () => {
          TweenMax.to(discover, 0.6, {
            y: 0,
            opacity: 1,
            ease: 'Sine.easeOut',
            onComplete: () => {
              onComplete()
              setTimeout(() => (this.canLeave = true), 100)
            },
          })
        },
      })
    })
  }

  setOnLeave () {
    const { onLeave } = this.props
    const { current: elm } = this.wrapper

    onLeave(onComplete => {
      this.pause()

      TweenMax.to(elm, 0.4, {
        opacity: 0,
        ease: 'Sine.easeIn',
        onComplete,
      })
    })
  }

  play () {
    const { current: video } = this.video

    video.play()
  }

  pause () {
    const { current: video } = this.video

    video.pause()
  }

  close () {
    const { setIntro } = this.props

    this.canLeave && setIntro(false)
  }

  render () {
    const { version } = this.props

    let src

    if (version === 'mobile') {
      src = require('~/media/video/mobile.mp4')
    } else {
      src = require('~/media/video/desktop.mp4')
    }

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        <video
          ref={this.video}
          className="video"
          src={src}
          muted
          autoPlay
          loop
          type="video/mp4"
          preload="metadata"
          playsInline
        />

        <div
          ref={this.discover}
          className="discover"
          onClick={this.close}>
          <span>Discover</span>
          <span className="line" />
        </div>

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: #11110f;
          }
          .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .discover {
            position: absolute;
            left: 50%;
            bottom: 0;
            font-family: 'Futura';
            font-size: 20px;
            font-weight: 300;
            color: white;
            text-align: center;
            cursor: pointer;
            transform: translateX(-50%);
          }
          @keyframes scroll {
            0% {
              height: 0;
              transform: translateY(0);
            }
            50% {
              height: 100%;
              transform: translateY(0);
            }
            100% {
              height: 100%;
              transform: translateY(100%);
            }
          }
          .line {
            position: relative;
            width: 1px;
            height: 55px;
            display: block;
            margin: 15px auto 0 auto;
            overflow: hidden;
          }
          .line:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            animation: 1.8s scroll infinite;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ version }) => ({
  version,
})

const mapDispatchToProps = dispatch => ({
  setIntro: payload => dispatch(Actions.setIntro(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Intro)
