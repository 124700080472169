import React from 'react'
import { Container } from '@inlet/react-pixi'
import pixiTicker from '~/hoc/pixiTicker'
import { flowRight, toArray, get } from 'lodash'
import { connect } from 'react-redux'
import loaderManager from '~/helpers/loaderManager'
import * as PIXI from 'pixi.js'
import { TweenMax } from 'gsap'

class Random extends React.Component {
  container = React.createRef()

  get isPlaying () {
    return this.animation && this.animation.playing
  }

  componentDidMount () {
    this.initFilter()
    this.initAnimation()
    this.triggerEffect()
  }

  componentDidUpdate (prevProps) {
    const { trigger } = this.props

    if (prevProps.trigger !== trigger) {
      this.triggerEffect()
    }
  }

  triggerEffect () {
    const { trigger } = this.props

    if (trigger) {
      const { value } = trigger

      if (value === 'show') {
        this.show()
      } else if (value === 'hide') {
        this.hide()
      }
    }
  }

  show () {
    TweenMax.to(this.container.current, 0.8, {
      alpha: 1,
      ease: 'Power2.easeIn',
    })
  }

  hide () {
    TweenMax.to(this.container.current, 0.8, {
      alpha: 0,
      ease: 'Power2.easeIn',
    })
  }

  initFilter () {
    const frag = require('~/glsl/random.frag')
    const shader = new PIXI.Filter('', frag, {})

    this.container.current.filters = [shader]
  }

  initAnimation () {
    const { app } = this.props

    const sprite = {
      data: require(`~/media/random/sprite.json`),
      image: require(`~/media/random/sprite.png`),
    }

    const render = resources => {
      const { texture } = resources[sprite.image]
      const spritesheet = new PIXI.Spritesheet(texture.baseTexture, sprite.data)

      spritesheet.parse(textures => {
        this.animation = new PIXI.extras.AnimatedSprite(toArray(textures))

        this.animation.loop = true
        this.animation.animationSpeed = 0.3
        this.animation.gotoAndPlay(0)

        app.renderer.plugins.prepare.upload(this.animation, () => {
          if (this.container.current) {
            this.container.current.addChild(this.animation)
            this.forceUpdate()
          }
        })
      })
    }

    loaderManager.loadTexture({
      id: 'random',
      src: sprite.image,
      callback: render,
    })
  }

  render () {
    const { viewport } = this.props
    const { width, height } = viewport

    if (this.animation) {
      this.animation.width = width
      this.animation.height = height
    }

    return (
      <Container
        ref={this.container}
        x={0}
        y={0}
        width={width}
        height={height}
        alpha={0}
      />
    )
  }
}

const mapStateToProps = ({ viewport, triggers }) => ({
  viewport,
  trigger: get(triggers, 'random', null),
})

export default flowRight(
  connect(mapStateToProps),
  pixiTicker
)(Random)
