import Stats from 'stats.js'

let stats

const init = () => {
  stats = new Stats()

  stats.showPanel(0)

  document.body.appendChild(stats.dom)

  window.requestAnimationFrame(function loop () {
    stats.update()
    window.requestAnimationFrame(loop)
  })
}

export default {
  init,
}
