import React from 'react'
import { flowRight, map, get } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import scrollWithEase from '~/helpers/scrollWithEase'
import Group from './Group'

class Content extends React.Component {
  state = {
    scroll: {},
  }

  wrapper = React.createRef()

  constructor () {
    super()

    this.setBody = this.setBody.bind(this)
    this.setScroll = this.setScroll.bind(this)
  }

  componentDidMount () {
    this.setBody()

    scrollWithEase.get(this.setScroll)
    scrollWithEase.on('scroll', this.setScroll)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setScroll)
  }

  componentDidUpdate (prevProps) {
    const { viewport } = this.props

    if (prevProps.viewport !== viewport) {
      this.setBody()
    }
  }

  setScroll (scroll) {
    this.setState({ scroll })
  }

  setBody () {
    const { viewport, setBody } = this.props

    const { width } = viewport

    const height = this.wrapper.current.offsetHeight

    setBody({
      width,
      height,
    })
  }

  render () {
    const { data, currentLook } = this.props
    const { scroll } = this.state

    const groupNodes = map(data, look => {
      const id = get(look, 'id')

      return <Group
        key={`gv3${ id }`}
        currentLook={currentLook}
        data={look} />
    })

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        {groupNodes}

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;

            width: 100%;
            min-height: 100%;
            transform: translateY(${ -scroll.value }px);
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ viewport }) => ({
  viewport,
})

const mapDispatchToProps = dispatch => ({
  setBody: payload => dispatch(Actions.setBody(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Content)
