import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import Content from './Content'
import scrollWithEase from '~/helpers/scrollWithEase'
import { TweenMax } from 'gsap'

class View2 extends React.Component {
  wrapper = React.createRef()

  componentDidMount () {
    this.handleScroll()
    this.setOnEnter()
    this.setOnLeave()
    this.setView2()
  }

  handleScroll () {
    const { config } = this.props

    let scrollTo = 0

    if (config.scroll) {
      scrollTo = config.scroll
    }

    scrollWithEase.reset(scrollTo)
  }

  setOnEnter () {
    const { onEnter } = this.props
    const { current: elm } = this.wrapper

    TweenMax.set(elm, { opacity: 0, y: 30 })

    onEnter(onComplete => {
      TweenMax.to(elm, 0.5, {
        opacity: 1,
        y: 0,
        ease: 'Sine.easeOut',
        onComplete,
      })
    })
  }

  setOnLeave () {
    const { onLeave } = this.props
    const { current: elm } = this.wrapper

    onLeave(onComplete => {
      TweenMax.to(elm, 0.5, {
        opacity: 0,
        y: 30,
        ease: 'Sine.easeIn',
        onComplete,
      })
    })
  }

  setView2 () {
    const { data, view2, setView2 } = this.props
    const { links, groups } = data

    if (view2) {
      return
    }

    setView2({
      links,
      groups,
    })
  }

  render () {
    const { view2 } = this.props

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        {view2 && <Content data={view2} />}

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            overflow: hidden;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ view2 }) => ({
  view2,
})

const mapDispatchToProps = dispatch => ({
  setView2: payload => dispatch(Actions.setView2(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(View2)
