import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import backArrow from '~/media/icons/back-arrow.svg'

class Back extends React.Component {
  constructor () {
    super()

    this.closeOverlay = this.closeOverlay.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown (e) {
    if (e.keyCode === 27) {
      this.closeOverlay()
    }
  }

  componentDidUpdate (prevProps) {
    const { look } = this.props

    if (prevProps.look !== look) {
      this.change()
    }
  }

  closeOverlay () {
    const { setOverlay } = this.props

    setOverlay(null)
  }

  render () {
    return (
      <div
        className="wrapper"
        onClick={this.closeOverlay}>
        <img
          className="back-arrow"
          src={backArrow}
          alt="" />
        <span className="label">Back</span>

        <style jsx>{`
          .wrapper {
            z-index: 2;
            position: fixed;
            top: 40px;
            left: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
          }
          :global(.no-touch) .wrapper:hover .back-arrow {
            transform: translateX(-3px);
          }
          .back-arrow {
            width: 8px;
            margin-right: 10px;
            margin-top: -1px;
            transition: 0.3s transform ease-in-out;
          }
          .label {
            font-family: 'Futura';
            font-size: 16px;
            font-weight: 400;
            color: #fff;
          }
        `}</style>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setOverlay: payload => dispatch(Actions.setOverlay(payload)),
})

export default flowRight(
  connect(
    null,
    mapDispatchToProps
  )
)(Back)
