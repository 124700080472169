import React from 'react'
import { flowRight, map, filter, get } from 'lodash'
import { connect } from 'react-redux'
import { Container, Sprite } from '@inlet/react-pixi'
import scrollWithEase from '~/helpers/scrollWithEase'
import * as PIXI from 'pixi.js'
import Block from './Block'
import Mouse from './Mouse'

class TrailContent extends React.Component {
  state = {
    scroll: {},
  }

  constructor () {
    super()

    this.setScroll = this.setScroll.bind(this)
  }

  componentDidMount () {
    scrollWithEase.get(this.setScroll)
    scrollWithEase.on('scroll', this.setScroll)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setScroll)
  }

  setScroll (scroll) {
    this.setState({ scroll })
  }

  render () {
    const { scroll } = this.state
    const { body, view1, app, addToTicker } = this.props
    const { width, height } = body

    const area = (
      <Sprite
        texture={PIXI.Texture.EMPTY}
        width={width}
        height={height} />
    )

    const trailBlocks = filter(get(view1, 'blocks'), block => {
      return block.trail === true
    })

    const blockNodes = map(trailBlocks, data => {
      return (
        <Block
          key={data.id}
          priority={data.y}
          scroll={{ ...scroll }}
          data={data}
        />
      )
    })

    return (
      <Container>
        <Mouse
          app={app}
          addToTicker={addToTicker} />
        <Container y={-scroll.value}>
          {area}
          {blockNodes}
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = ({ body, viewport, view1 }) => ({
  body,
  viewport,
  view1,
})

export default flowRight(connect(mapStateToProps))(TrailContent)
