import React from 'react'
import { flowRight, map, forEach, find, indexOf } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import scrollWithEase from '~/helpers/scrollWithEase'
import Group from './Group'
import Link from './Link'

class Content extends React.Component {
  state = {
    scroll: {},
  }

  wrapper = React.createRef()

  constructor () {
    super()

    this.setScroll = this.setScroll.bind(this)
  }

  componentDidMount () {
    this.setBody()

    scrollWithEase.get(this.setScroll)
    scrollWithEase.on('scroll', this.setScroll)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setScroll)
  }

  componentDidUpdate (prevProps) {
    const { viewport } = this.props

    if (prevProps.viewport !== viewport) {
      this.setBody()
    }
  }

  setScroll (scroll) {
    this.setState({ scroll })
  }

  setBody () {
    const { viewport, setBody } = this.props

    const { width } = viewport

    const height = this.wrapper.current.offsetHeight

    setBody({
      width,
      height,
    })
  }

  render () {
    const { scroll } = this.state
    const { data } = this.props
    const { groups, links } = data

    const groupNodes = map(groups, group => {
      const { id, blocks } = group

      return <Group
        key={id}
        blocks={blocks} />
    })

    forEach(links, data => {
      const { id, label, link, target, position } = data

      const group = find(groupNodes, { key: position })
      const groupIndex = indexOf(groupNodes, group)

      const node = <Link
        key={id}
        label={label}
        link={link}
        target={target} />

      groupNodes.splice(groupIndex + 1, 0, node)
    })

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        {groupNodes}

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            align-content: baseline;
            flex-wrap: wrap;
            width: 100%;
            min-height: 100%;
            padding: 130px 20px;
            transform: translateY(${ -scroll.value }px);
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ viewport }) => ({
  viewport,
})

const mapDispatchToProps = dispatch => ({
  setBody: payload => dispatch(Actions.setBody(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Content)
