import React from 'react'
import { get, debounce } from 'lodash'
import Button from '~/components/Button'
import { TweenMax } from 'gsap'

class Link extends React.Component {
  state = {
    look: null,
  }

  wrapper = React.createRef()

  componentDidUpdate (prevProps) {
    const { look } = this.props

    if (prevProps.look !== look) {
      this.change()
    }
  }

  change = debounce(() => {
    const { look } = this.props
    const { look: previousLook } = this.state
    const { current: elm } = this.wrapper

    const animated = previousLook

    const set = () => {
      this.setState({ look })

      if (animated) {
        TweenMax.set(elm, {
          y: 10,
        })

        TweenMax.to(elm, 0.3, {
          opacity: 1,
          y: 0,
          delay: 0.25,
          ease: 'Sine.easeOut',
        })
      }
    }

    if (animated) {
      TweenMax.to(elm, 0.3, {
        opacity: 0,
        y: 10,
        ease: 'Sine.easeIn',
        onComplete: set,
      })
    } else {
      set()
    }
  })

  render () {
    const { look } = this.state

    if (!look) {
      return <React.Fragment />
    }

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        <Button
          label={`Shop ${ look.label }`}
          link={get(look, 'link')}
          target="_blank"
          background={false}
        />
      </div>
    )
  }
}

export default Link
