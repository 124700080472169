import React from 'react'

function renderIfClientHoc (WrappedComponent) {
  class RenderIfClientHoc extends React.Component {
    state = {
      isMount: false,
    }

    componentDidMount () {
      this.setState({
        isMount: true,
      })
    }

    render () {
      const { isMount } = this.state

      return isMount ? <WrappedComponent {...this.props} /> : <div />
    }
  }

  return RenderIfClientHoc
}

export default renderIfClientHoc
