import React from 'react'
import { debounce } from 'lodash'
import { connect } from 'react-redux'
import scrollWithEase from '~/helpers/scrollWithEase'
import getScreenSize from '~/helpers/getScreenSize'

class ScrollWithEase extends React.Component {
  componentDidMount () {
    this.init()
    this.setMaxScroll()
    this.handleGucciBanner()
  }

  componentDidUpdate (prevProps) {
    const { isActive, isScrollActive, body } = this.props

    if (prevProps.body !== body) {
      this.setMaxScroll()
    }

    if (prevProps.isActive !== isActive) {
      this.active()
    }

    if (prevProps.isScrollActive !== isScrollActive) {
      this.toggle()
    }
  }

  init () {
    const { isMobileDevice, isScrollActive } = this.props

    scrollWithEase.init({
      isActive: isScrollActive,
      deltaMultiplier: isMobileDevice ? 1 : 0.14,
    })
  }

  setMaxScroll () {
    const { body, viewport, isMobileDevice } = this.props

    const { height: windowHeight } = getScreenSize(isMobileDevice)

    const application = body.height - viewport.height
    const document = body.height - windowHeight

    scrollWithEase.setMaxScroll({
      application,
      document,
    })
  }

  toggle () {
    const { isScrollActive } = this.props

    if (isScrollActive) {
      scrollWithEase.active()
    } else {
      scrollWithEase.disactive()
    }
  }

  handleGucciBanner () {
    const toggle = debounce(isVisible => {
      const method = isVisible ? 'showGucciBanner' : 'hideGucciBanner'
      window[method]()
    })

    scrollWithEase.on('scroll', scroll => {
      toggle(scroll.maxScroll > 0 && scroll.value >= scroll.maxScroll - 100)
    })
  }

  render () {
    const { children } = this.props

    return children
  }
}

const mapStateToProps = ({
  body,
  viewport,
  isMobileDevice,
  isScrollActive,
  easterEgg,
}) => ({
  body,
  viewport,
  isMobileDevice,
  isScrollActive,
  easterEgg,
})

export default connect(mapStateToProps)(ScrollWithEase)
