import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import { withPixiApp, TilingSprite } from '@inlet/react-pixi'
import loaderManager from '~/helpers/loaderManager'
import { TweenMax } from 'gsap'

class Marquee extends React.Component {
  state = {
    texture: null,
    width: 0,
    height: 0,
    progress: 0,
  }

  componentDidMount () {
    this.initTexture()
    this.initTween()
  }

  initTexture () {
    const { data } = this.props
    const { image } = data

    const src = require(`~/${ image }`)

    const callback = resources => {
      const { texture } = resources[src]

      const { width, height } = texture

      this.setState({
        texture,
        width,
        height,
      })
    }

    loaderManager.loadTexture({
      id: data.id,
      src,
      callback,
    })
  }

  initTween () {
    const { data } = this.props
    const { speed } = data

    const onUpdate = tween => {
      const { progress } = tween.target

      this.setState({
        progress,
      })
    }

    this.tween = TweenMax.to(
      {
        progress: 0,
      },
      speed,
      {
        progress: 1,
        ease: 'Linear.easeNone',
        paused: false,
        repeat: -1,
        onUpdateParams: ['{self}'],
        onUpdate,
      }
    )
  }

  render () {
    const { viewport, body, data } = this.props
    const { texture, height, progress } = this.state
    const { y } = data

    return !texture ? (
      <React.Fragment />
    ) : (
      <TilingSprite
        texture={texture}
        y={y * body.scaleFactor}
        width={viewport.width}
        height={height}
        tilePosition={{ x: -viewport.width * progress, y: 0 }}
      />
    )
  }
}

const mapStateToProps = ({ viewport, body }) => ({
  viewport,
  body,
})

export default flowRight(
  connect(mapStateToProps),
  withPixiApp || Marquee
)(Marquee)
