import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import scrollWithEase from '~/helpers/scrollWithEase'

function nodeScrollObserverHoc (WrappedComponent) {
  class NodeScrollObserverHoc extends React.Component {
    progress = {
      value: null,
      isEntered: null,
      isLeft: null,
    }

    constructor () {
      super()

      this.setProgress = this.setProgress.bind(this)
    }

    componentDidMount () {
      this.elm = ReactDOM.findDOMNode(this)

      scrollWithEase.get(this.setProgress)
      scrollWithEase.on('scroll', this.setProgress)
    }

    componentWillUnmount () {
      scrollWithEase.off('scroll', this.setProgress)
    }

    setProgress () {
      const { viewport } = this.props
      const { height: viewportHeight } = viewport

      const bounds = this.elm.getBoundingClientRect()

      const max = viewportHeight + bounds.height

      const value = 1 - (bounds.y + bounds.height) / max
      const isEntered = value >= 0 && value <= 1
      const isLeft = !isEntered

      Object.assign(this.progress, {
        value,
        isEntered,
        isLeft,
      })
    }

    render () {
      return <WrappedComponent
        progress={this.progress}
        {...this.props} />
    }
  }

  const mapStateToProps = ({ viewport }) => ({
    viewport,
  })

  return connect(mapStateToProps)(NodeScrollObserverHoc)
}

export default nodeScrollObserverHoc
