import React from 'react'
import { connect } from 'react-redux'

function pixiBoundsHoc (WrappedComponent) {
  class PixiBoundsHoc extends React.Component {
    render () {
      const { data, body } = this.props
      const { x, y, width, height } = data
      const { scaleFactor } = body

      const bounds = {
        x: x * scaleFactor,
        y: y * scaleFactor,
        width: width * scaleFactor,
        height: height * scaleFactor,
      }

      return <WrappedComponent
        bounds={bounds}
        {...this.props} />
    }
  }

  const mapStateToProps = ({ body }) => ({
    body,
  })

  return connect(mapStateToProps)(PixiBoundsHoc)
}

export default pixiBoundsHoc
