import React from 'react'
import { get, debounce } from 'lodash'
import Image from '~/components/Image'
import { TweenMax } from 'gsap'

class Nav extends React.Component {
  state = {
    look: null,
    isActive: true,
  }

  wrapper = React.createRef()

  constructor () {
    super()

    this.onClick = this.onClick.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.onKeyDown)
  }

  onKeyDown (e) {
    const { type } = this.props

    const code = type + e.keyCode

    if (code === 'prev37' || code === 'next39') {
      this.onClick()
    }
  }

  componentDidUpdate (prevProps) {
    const { look } = this.props

    if (prevProps.look !== look) {
      this.change()
    }
  }

  change = debounce(() => {
    const { look, direction, delay } = this.props
    const { look: previousLook } = this.state
    const { current: elm } = this.wrapper

    const animated = previousLook

    this.setState({ isActive: false })

    const set = () => {
      const makeActive = () => this.setState({ isActive: true })

      this.setState({ look })

      if (animated) {
        TweenMax.set(elm, {
          x: 30 * direction,
        })

        TweenMax.to(elm, 0.4, {
          opacity: 1,
          x: 0,
          ease: 'Sine.easeOut',
          onComplete: makeActive,
        })
      } else {
        makeActive()
      }
    }

    if (animated) {
      TweenMax.to(elm, 0.4, {
        opacity: 0,
        x: 30 * direction * -1,
        delay,
        ease: 'Sine.easeIn',
        onComplete: set,
      })
    } else {
      set()
    }
  })

  onClick () {
    const { onClick } = this.props
    const { isActive } = this.state

    if (isActive) {
      onClick()
    }
  }

  render () {
    const { type, label } = this.props
    const { look } = this.state

    if (!look) {
      return <React.Fragment />
    }

    const isPrev = type === 'prev'

    return (
      <div
        ref={this.wrapper}
        className="wrapper"
        onClick={this.onClick}>
        <div className="image">
          <Image
            path={get(look, 'image', '')}
            sizes="14vw"
            preload />
        </div>

        <div className="label-container">
          <span className="label">{label}</span>
        </div>

        <style jsx>{`
          .wrapper {
            width: 14vw;
            margin: 0 auto;
            cursor: pointer;
            overflow: hidden;
          }
          :global(.no-touch) .wrapper:hover .image {
            transform: scale(1.06);
          }
          :global(.no-touch) .wrapper:hover .label {
            transform: translateX(${ isPrev ? '-5px' : '5px' });
          }
          .image {
            transition: 0.3s transform ease-in-out;
          }
          .label-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
            padding: 10px;
            margin-top: -1px;
            font-family: 'Futura';
            font-size: 20px;
            font-weight: 300;
            text-align: center;
            color: #fff;
            background: #292929;
            border: solid 1px white;
          }
          .label {
            transition: 0.3s transform ease-in-out;
          }
        `}</style>
      </div>
    )
  }
}

export default Nav
