import React from 'react'
import { flowRight, debounce, toArray } from 'lodash'
import { connect } from 'react-redux'
import { Sprite } from '@inlet/react-pixi'
import * as PIXI from 'pixi.js'
import { TweenMax } from 'gsap'

class Mouse extends React.Component {
  sprite = React.createRef()

  mouse = {
    x: 0,
    y: 0,
    radius: 0,
  }
  isMoving = false

  constructor () {
    super()

    this.onMouseMove = this.onMouseMove.bind(this)
  }

  componentDidMount () {
    this.initFilter()

    window.addEventListener('mousemove', this.onMouseMove)
  }

  componentWillUnmount () {
    window.removeEventListener('mousemove', this.onMouseMove)
  }

  initFilter () {
    const { addToTicker, app } = this.props
    const { renderer } = app

    const frag = require('~/glsl/mouse.frag')

    const uniforms = {
      uMouse: {
        type: 'v3',
        value: [0, 0, 0],
      },
      uResolution: {
        type: 'v2',
        value: [0, 0],
      },
    }

    const shader = new PIXI.Filter('', frag, uniforms)

    shader.padding = 0

    this.sprite.current.filters = [shader]

    addToTicker(() => {
      const { uniforms } = shader

      uniforms.uResolution[0] = renderer.width
      uniforms.uResolution[1] = renderer.height

      uniforms.uMouse = toArray(this.mouse)
    })
  }

  onMouseMove (e) {
    const { clientX: x, clientY: y } = e

    Object.assign(this.mouse, { x, y })

    this.extend()
  }

  extend () {
    if (!this.isMoving) {
      this.isMoving = true

      TweenMax.to(this.mouse, 0.6, {
        radius: 1,
        ease: 'Power2.easeOut',
      })
    }

    this.reduce()
  }

  reduce = debounce(() => {
    this.isMoving = false

    TweenMax.to(this.mouse, 0.2, {
      radius: 0,
      ease: 'Power2.easeOut',
    })
  }, 10)

  render () {
    const { viewport } = this.props
    const { width, height } = viewport

    return (
      <Sprite
        ref={this.sprite}
        texture={PIXI.Texture.EMPTY}
        width={width}
        height={height}
      />
    )
  }
}

const mapStateToProps = ({ viewport }) => ({
  viewport,
})

export default flowRight(connect(mapStateToProps))(Mouse)
