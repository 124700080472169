import React from 'react'
import { flowRight, get } from 'lodash'
import Look from './Look'
import EasterEgg from './EasterEgg'
import nodeScrollObserverHoc from '~/hoc/nodeScrollObserverHoc'
import nodeSetPriorityHoc from '~/hoc/nodeSetPriorityHoc'
import triggersManagerHoc from '~/hoc/triggersManagerHoc'

class Block extends React.Component {
  render () {
    const { data } = this.props
    const { position, image, look, overlay, easteregg } = data

    let marginTop = 0

    switch (position) {
      case 1:
        marginTop = '9.7%'
        break
      case 2:
        marginTop = '22.65%'
        break
      default:
        marginTop = 0
    }

    let content = <React.Fragment />

    if (image) {
      content = (
        <Look
          id={get(look, 'id', '')}
          image={image}
          label={get(look, 'label', '')}
          overlay={overlay}
        />
      )
    } else if (easteregg) {
      content = <EasterEgg />
    }

    return (
      <div className="wrapper">
        {content}

        <style jsx>{`
          .wrapper {
            position: relative;
            display: block;
            width: 33.333333%;
            margin-top: ${ marginTop };
            overflow: hidden;
          }
          .wrapper:before {
            content: '';
            display: block;
            height: 0px;
            padding-bottom: 150.48%;
          }
        `}</style>
      </div>
    )
  }
}

export default flowRight(
  nodeScrollObserverHoc,
  nodeSetPriorityHoc,
  triggersManagerHoc
)(Block)
