import React from 'react'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import scrollWithEase from '~/helpers/scrollWithEase'

class ViewOrchestrator extends React.Component {
  state = {
    previous: null,
  }

  componentDidMount () {
    this.activeLoader()
  }

  componentDidUpdate (prevProps) {
    const {
      isAppReady,
      version,
      activeView,
      intro,
      overlay,
      easterEgg,
    } = this.props

    if (prevProps.isAppReady !== isAppReady) {
      isAppReady && this.onAppReady()
    }

    if (prevProps.intro !== intro) {
      this.handleIntro()
    }

    if (prevProps.overlay !== overlay) {
      this.handleOverlay()
    }

    if (prevProps.easterEgg !== easterEgg) {
      this.handleEasterEgg()
    }

    if (prevProps.version !== version) {
      this.refreshView()
    }

    if (prevProps.activeView.key !== activeView.key) {
      this.setPrevious(prevProps.activeView)
    }
  }

  onAppReady () {
    const { setIntro } = this.props

    setIntro(true)
    // this.activeDefault()
  }

  activeLoader () {
    const { changeView, views } = this.props

    const { VIEW_LOADER } = views

    changeView({
      key: VIEW_LOADER,
    })
  }

  activeDefault () {
    const { changeView, views, setScroll } = this.props

    const { VIEW_DEFAULT } = views

    setTimeout(() => {
      setScroll(true)
    }, 2000)

    changeView({
      key: VIEW_DEFAULT,
    })
  }

  handleIntro () {
    const { changeView, setScroll, views, intro } = this.props

    const { VIEW_INTRO, VIEW_DEFAULT } = views

    if (intro) {
      changeView({
        key: VIEW_INTRO,
      })
    } else {
      setTimeout(() => {
        setScroll(true)
      }, 2000)

      changeView({
        key: VIEW_DEFAULT,
      })
    }
  }

  handleOverlay () {
    const { overlay, changeView, views } = this.props
    const { previous } = this.state

    const { VIEW_OVERLAY } = views

    if (overlay) {
      changeView({
        key: VIEW_OVERLAY,
      })
    } else {
      const { key, scroll } = previous

      changeView({
        key,
        scroll,
      })
    }
  }

  handleEasterEgg () {
    const { easterEgg, setScroll } = this.props

    setScroll(!easterEgg)
  }

  refreshView () {
    const { changeView, activeView } = this.props

    changeView({
      key: activeView.key,
    })
  }

  setPrevious (previous) {
    const scroll = scrollWithEase.get()

    Object.assign(previous, {
      scroll: scroll.value,
    })

    this.setState({
      previous,
    })
  }

  render () {
    const { children, activeView } = this.props

    return activeView.key ? children : <React.Fragment />
  }
}

const mapStateToProps = ({
  isAppReady,
  version,
  activeView,
  intro,
  overlay,
  views,
  easterEgg,
}) => ({
  isAppReady,
  version,
  activeView,
  intro,
  overlay,
  views,
  easterEgg,
})

const mapDispatchToProps = dispatch => ({
  setIntro: payload => dispatch(Actions.setIntro(payload)),
  setScroll: payload => dispatch(Actions.setScroll(payload)),
  changeView: payload => dispatch(Actions.changeView(payload)),
  refreshView: payload => dispatch(Actions.refreshView(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewOrchestrator)
