import { merge, set as lSet, debounce } from 'lodash'
import EventEmitter from 'events'

const Module = payload => {
  const options = merge(
    {
      elm: {
        width: 0,
        height: 0,
      },
      parent: {
        width: 0,
        height: 0,
      },
      behavior: 'contain',
      anchor: {
        x: 0.5,
        y: 0.5,
      },
      focus: {
        x: 0.5,
        y: 0.5,
      },
      scale: 1,
      autoUpdate: false,
      requestAnimationFrame: false,
    },
    payload
  )

  const values = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  }

  const events = new EventEmitter()

  const set = (key, value) => {
    lSet(options, key, value)

    events.emit(`update_${ key }`, value)

    update()
  }

  const updateParentSize = () => {
    const { parent } = options

    if (parent.elm) {
      set('parent.width', parent.elm.offsetWidth)
      set('parent.height', parent.elm.offsetHeight)
    }
  }

  const adjustFit = () => {
    const { scale, focus, anchor, parent, elm } = options

    values.width = parent.width * scale
    values.height = (parent.width * elm.height * scale) / elm.width

    if (values.height < parent.height * scale) {
      values.width = (parent.height * elm.width * scale) / elm.height
      values.height = parent.height * scale
    }

    values.x = Math.max(
      parent.width - values.width,
      Math.min(0, parent.width * anchor.x - values.width * focus.x)
    )
    values.y = Math.max(
      parent.height - values.height,
      Math.min(0, parent.height * anchor.y - values.height * focus.y)
    )
  }

  const adjustContain = () => {
    const { scale, focus, anchor, parent, elm } = options

    values.width = parent.width * scale
    values.height = (parent.width * elm.height * scale) / elm.width

    if (values.height > parent.height * scale) {
      values.width = (parent.height * elm.width * scale) / elm.height
      values.height = parent.height * scale
    }

    values.x = parent.width * anchor.x - values.width * focus.x
    values.y = parent.height * anchor.y - values.height * focus.y
  }

  const update = () => {
    switch (options.behavior) {
      case 'fit':
        adjustFit()
        break
      default:
        adjustContain()
    }

    events.emit('update', values)
  }

  let ticket = false

  const resize = () => {
    if (options.requestAnimationFrame) {
      if (ticket === true) {
        ticket = false

        window.requestAnimationFrame(() => {
          ticket = true

          updateParentSize()
        })
      }
    } else {
      updateParentSize()
    }
  }

  const on = (eventName, cb) => {
    events.on(eventName, cb)
  }

  if (options.autoUpdate) {
    window.addEventListener('resize', resize, false)
    window.addEventListener(
      'orientationchange',
      () => {
        return setTimeout(resize, 500)
      },
      false
    )
  }

  events.on('update_parent.elm', updateParentSize)

  updateParentSize()

  return {
    values,
    update,
    set,
    on,
  }
}

export default Module
