import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import Image from '~/components/Image'
import scrollWithEase from '~/helpers/scrollWithEase'

class Look extends React.Component {
  wrapper = React.createRef()

  componentDidMount () {
    const { isCurrent } = this.props

    if (isCurrent) {
      this.scrollTo()
    }
  }

  scrollTo () {
    const bounds = this.wrapper.current.getBoundingClientRect()

    scrollWithEase.reset(bounds.top + 1)
  }

  render () {
    const { data } = this.props
    const { image } = data

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        <Image
          path={image}
          sizes="80vw"
          lazyload />

        <style jsx>{`
          .wrapper {
            position: relative;
            width: 100%;
            margin-top: -1px;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ body }) => ({
  body,
})

const mapDispatchToProps = dispatch => ({
  setOverlay: payload => dispatch(Actions.setOverlay(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Look)
