import React from 'react'
import { flowRight, get, map, concat, debounce } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import Image from '~/components/Image'
import { TweenMax } from 'gsap'

class Look extends React.Component {
  state = {
    look: null,
  }

  wrapper = React.createRef()

  componentDidMount () {
    this.setBody()
  }

  componentDidUpdate (prevProps, prevState) {
    const { viewport, look } = this.props

    if (prevProps.look !== look) {
      this.change()
    }

    if (prevProps.viewport !== viewport) {
      this.setBody()
    }

    if (prevState.look !== look) {
      this.setBody()
    }
  }

  change = debounce(() => {
    const { look, resetScroll } = this.props
    const { look: previousLook } = this.state
    const { current: elm } = this.wrapper

    const animated = previousLook

    const set = () => {
      this.setState({ look })

      resetScroll()

      if (animated) {
        TweenMax.to(elm, 0.5, {
          opacity: 1,
          ease: 'Sine.easeOut',
        })
      }
    }

    if (animated) {
      TweenMax.to(elm, 0.5, {
        opacity: 0,
        ease: 'Sine.easeIn',
        onComplete: set,
      })
    } else {
      set()
    }
  })

  setBody () {
    const { viewport, setBody } = this.props
    const { current: elm } = this.wrapper

    const { width } = viewport

    const height = elm.offsetHeight

    setBody({
      width,
      height,
    })
  }

  render () {
    const { look } = this.state

    const closeups = get(look, 'closeups', [])
    const items = concat(look, closeups)

    const itemNodes = look ? (
      map(items, item => (
        <Image
          key={item.id}
          path={get(item, 'image', '')}
          sizes="64vw"
          preload
        />
      ))
    ) : (
      <React.Fragment />
    )

    return (
      <div
        ref={this.wrapper}
        className="wrapper">
        {itemNodes}

        <style jsx>{`
          .wrapper {
            width: 64vw;
            margin: 0 auto;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ viewport }) => ({
  viewport,
})

const mapDispatchToProps = dispatch => ({
  setBody: payload => dispatch(Actions.setBody(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Look)
