import React from 'react'
import BaseLayout from '~/layouts/base'
import Page from '~/components/Page'
import { man } from '~/data'

class ManPage extends React.Component {
  render () {
    return (
      <BaseLayout>
        <Page data={man} />
      </BaseLayout>
    )
  }
}

export default ManPage
