import React from 'react'
import { connect } from 'react-redux'
import getOrientation from '~/helpers/getOrientation'
import gif from '~/media/other/rotate-your-device.gif'

class RotateYourDevide extends React.Component {
  state = {
    isVisibile: false,
  }

  componentDidMount () {
    const { isMobileDevice } = this.props

    const onRotate = () => {
      const isVisibile = isMobileDevice && getOrientation() === 'landscape'
      this.setState({ isVisibile })
    }

    window.addEventListener('orientationchange', () =>
      setTimeout(onRotate, 500)
    )

    onRotate()
  }

  render () {
    const { isVisibile } = this.state

    return (
      <div className="wrapper">
        <div className="marquee top" />

        <img
          className="gif"
          src={gif} />

        <span className="label">Please rotate your device</span>

        <div className="marquee bottom" />

        <style jsx>{`
          .wrapper {
            z-index: 3;
            display: ${ isVisibile ? 'flex' : 'none' };
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #00b31a;
          }
          @keyframes marquee-1 {
            0% {
              background-position: 0 center;
            }
            100% {
              background-position: -100% center;
            }
          }
          @keyframes marquee-2 {
            0% {
              background-position: 0 center;
            }
            100% {
              background-position: 100% center;
            }
          }
          .marquee {
            position: absolute;
            left: 0;
            width: 100%;
            height: 10vw;
            background-size: 22vw auto;
          }
          .top {
            top: 0;
            animation: marquee-1 10s linear 0s infinite;
          }
          .bottom {
            bottom: 0;
            animation: marquee-2 10s linear 0s infinite;
          }
          .gif {
            width: 187px;
            height: 187px;
            margin-top: -35px;
          }
          .label {
            font: 18px/1em Futura;
            text-transform: uppercase;
            color: #fff;
            margin-top: -18px;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ isMobileDevice }) => ({
  isMobileDevice,
})

export default connect(mapStateToProps)(RotateYourDevide)
