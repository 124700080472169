import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const EmptyLayout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
              siteUrl
              shareImage
            }
          }
        }
      `}
      render={data => (
        <div>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <meta
              property="og:type"
              content="website" />
            <meta
              property="og:site_name"
              content="Gucci" />
            <meta
              property="og:url"
              content={data.site.siteMetadata.siteUrl} />
            <meta
              property="og:title"
              content={data.site.siteMetadata.title} />
            <meta
              property="og:description"
              content={data.site.siteMetadata.description}
            />
            <meta
              property="og:image"
              content={data.site.siteMetadata.shareImage}
            />
            <meta
              name="twitter:card"
              content="summary_large_image" />
            <meta
              name="twitter:title"
              content={data.site.siteMetadata.title} />
            <meta
              name="twitter:description"
              content={data.site.siteMetadata.description}
            />
            <meta
              name="twitter:image"
              content={data.site.siteMetadata.shareImage}
            />
          </Helmet>

          {children}

          <style
            jsx
            global>{`
            html {
              height: 100%;
              overflow: hidden;
              overscroll-behavior: none;
            }
            body {
              height: 100%;
              background: #11110f;
            }
          `}</style>
        </div>
      )}
    />
  )
}

export default EmptyLayout
