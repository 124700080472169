import React from 'react'
import { flowRight } from 'lodash'
import { connect } from 'react-redux'
import { Actions } from '~/modules/action'
import Image from '~/components/Image'

class Look extends React.Component {
  constructor () {
    super()

    this.openLook = this.openLook.bind(this)
  }

  openLook () {
    const { overlay, openLook, version, id } = this.props

    switch (version) {
      case 'mobile':
        openLook(id)
        break
      default:
        openLook(overlay)
    }
  }

  render () {
    const { image, debugMode, id } = this.props

    return (
      <div
        className="wrapper"
        onClick={this.openLook}>
        <Image
          path={image}
          sizes="17vw"
          lazyload />
        {debugMode ? <div className="debugId">{id}</div> : ''}
        <style jsx>{`
          .wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }

          .debugId {
            position: absolute;
            top: 0;
            left: 0;
            color: white;
            z-index: 100;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ body, version, debugMode }) => ({
  body,
  version,
  debugMode,
})

const mapDispatchToProps = dispatch => ({
  openLook: payload => dispatch(Actions.openLook(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Look)
