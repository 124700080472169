import React from 'react'
import { connect } from 'react-redux'
import scrollWithEase from '~/helpers/scrollWithEase'

function pixiScrollObserverHoc (WrappedComponent) {
  class PixiScrollObserverHoc extends React.Component {
    state = {
      observerReady: false,
    }

    progress = {
      value: null,
      isEntered: null,
      isLeft: null,
    }

    constructor () {
      super()

      this.setProgress = this.setProgress.bind(this)
    }

    componentDidMount () {
      scrollWithEase.on('scroll', this.setProgress)
    }

    componentDidUpdate (prevProps) {
      const { bounds } = this.props

      if (prevProps.bounds !== bounds) {
        scrollWithEase.get(this.setProgress)
      }
    }

    componentWillUnmount () {
      scrollWithEase.off('scroll', this.setProgress)
    }

    setProgress (scroll) {
      const { bounds, viewport } = this.props
      const { height: viewportHeight } = viewport

      let value = null
      let isEntered = null
      let isLeft = null

      if (bounds.width && bounds.height) {
        const max = viewportHeight + bounds.height

        value = (scroll.value - bounds.y + viewportHeight) / max
        isEntered = value >= 0 && value <= 1
        isLeft = !isEntered
      }

      Object.assign(this.progress, {
        value,
        isEntered,
        isLeft,
      })
    }

    render () {
      return <WrappedComponent
        progress={this.progress}
        {...this.props} />
    }
  }

  const mapStateToProps = ({ viewport }) => ({
    viewport,
  })

  return connect(mapStateToProps)(PixiScrollObserverHoc)
}

export default pixiScrollObserverHoc
