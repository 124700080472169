import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

function nodeSetPriorityHoc (WrappedComponent) {
  class NodeSetPriorityHoc extends React.Component {
    state = {
      priority: 0,
    }

    componentDidMount () {
      this.elm = ReactDOM.findDOMNode(this)

      this.setPriority()
    }

    componentDidUpdate (prevProps) {
      const { viewport } = this.props

      if (prevProps.viewport !== viewport) {
        this.setPriority()
      }
    }

    setPriority () {
      const priority = this.elm.offsetTop

      this.setState({
        priority,
      })
    }

    render () {
      const { priority } = this.state

      return <WrappedComponent
        priority={priority}
        {...this.props} />
    }
  }

  const mapStateToProps = ({ viewport }) => ({
    viewport,
  })

  return connect(mapStateToProps)(NodeSetPriorityHoc)
}

export default nodeSetPriorityHoc
