import { get, find, map, compact, flatten, concat, omit } from 'lodash'

import woman from './woman'
import man from './man'
import views from './views.json'

/**
 * make the look collection a flatten array
 */
function getFlattenLooks (looks) {
  const _closeups = flatten(compact(map(looks, 'closeups')))

  const _looks = map(looks, look => omit(look, 'closeups'))

  return concat(_looks, _closeups)
}

/**
 * get the look by id
 * it searchs also between closeups
 */
function findLook (looks, id) {
  const flattenLooks = getFlattenLooks(looks)

  return find(flattenLooks, { id })
}

/**
 * parse blocks by adding the image attribute whose value
 * is taken from the relative look object in looks.json
 */
const parseBlocks = (blocks, looks) => {
  return map(blocks, ({ look, image, ...attributes }) => {
    look = findLook(looks, look)

    if (look) {
      image = get(look, 'image', '')
    }

    return {
      look,
      image,
      ...attributes,
    }
  })
}

/**
 * parse data
 */
const parseData = data => {
  const { view1, view2, looks } = data

  data.view1.blocks = parseBlocks(view1.blocks, looks)

  data.view2.groups = view2.groups.map(group => {
    let { blocks } = group

    blocks = parseBlocks(blocks, looks)

    return {
      ...group,
      blocks,
    }
  })

  data.views = views
}

parseData(woman)
parseData(man)

export { woman, man }
