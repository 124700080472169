import React from 'react'
import { connect } from 'react-redux'
import Srcset from '~/helpers/Srcset'
import scrollWithEase from '~/helpers/scrollWithEase'
import loaderManager from '~/helpers/loaderManager'
import * as PIXI from 'pixi.js'

function pixiImageHoc (WrappedComponent) {
  class PixiImageHoc extends React.Component {
    state = {
      src: '',
      ready: false,
      width: 1,
      height: 1,
    }

    constructor () {
      super()

      this.setImage = this.setImage.bind(this)
    }

    componentDidMount () {
      this.initSrcset()

      setTimeout(this.setImage)

      scrollWithEase.on('scroll', this.setImage)
    }

    componentWillUnmount () {
      scrollWithEase.off('scroll', this.setImage)
    }

    componentDidUpdate (prevProps) {
      const { viewport } = this.props

      if (prevProps.viewport !== viewport) {
        this.setImage()
      }
    }

    initSrcset () {
      const { data } = this.props

      if (!data.image) {
        return
      }

      const src = require(`~/${ data.image }`)

      this.placeholder = src.placeholder

      this.setState({
        src: src.placeholder,
        width: src.width,
        height: src.height,
      })

      this.srcset = new Srcset({ images: src.images })
    }

    setImage () {
      const { src: currentSrc } = this.state
      const { data, progress, bounds } = this.props

      if (!data.image) {
        return
      }

      if (progress.value && progress.value > -1.8) {
        const image = this.srcset.get(bounds.width)

        const { path: src } = image

        if (currentSrc !== src) {
          const { id } = data

          const loader = loaderManager.get(id)

          const isAlreadyLoading = loader && loader.src === src

          if (!isAlreadyLoading) {
            loaderManager.remove(id)

            loaderManager.loadTexture({
              id,
              src,
              callback: () => {
                this.setState({
                  src: src,
                  ready: true,
                })
              },
            })
          }
        }
      }
    }

    render () {
      const { src, ready, width, height } = this.state

      let texture = PIXI.Texture.EMPTY

      if (src) {
        texture = PIXI.Texture.from(src)
      }

      const imageSize = {
        width,
        height,
      }

      return (
        <WrappedComponent
          texture={texture}
          ready={ready}
          imageSize={imageSize}
          {...this.props}
        />
      )
    }
  }

  const mapStateToProps = ({ viewport }) => ({
    viewport,
  })

  return connect(mapStateToProps)(PixiImageHoc)
}

export default pixiImageHoc
